import React from "react";
import { useTranslation } from "react-i18next"

import { Helmet } from "react-helmet";


export default function CreateWeb() {
    const [t] = useTranslation('global');

    return (
        <div className="Template-left">
            <Helmet>
                <title>Blauker - {t("nav.op5")}</title>
            </Helmet>
            <div className="container">
                <h1><b>{t("web.title.t1")} <d className="blue-text">{t("web.title.t2")}</d>:</b></h1>
                <div className="mini-container text-center">
                    <h3>{t("web.subtitle.t1")}</h3>
                    {t("web.subtitle.t2")} <br />
                </div>

                <br /><br />

                <h3><b>{t("web.contains.title")}</b></h3>
                <ul>
                    <li><b className="blue-text">{t("web.contains.c1")}</b>: {t("web.contains.ct1")}</li>
                    <li><b className="blue-text">{t("web.contains.c2")}</b>: {t("web.contains.ct2")}</li>
                    <li><b className="blue-text">{t("web.contains.c3")}</b>: {t("web.contains.ct3")}</li>
                    <li><b className="blue-text">{t("web.contains.c4")}</b>: {t("web.contains.ct4")}</li>
                    <li><b className="blue-text">{t("web.contains.c5")}</b>: {t("web.contains.ct5")}</li>
                    <li><b className="blue-text">{t("web.contains.c6")}</b>: {t("web.contains.ct6")}</li>
                </ul> <br />

                {t("web.note.n1.t1")} <b>{t("web.note.n1.t2")}</b>, {t("web.note.n1.t3")} <br />
                <i>{t("web.note.n1.t4")}</i> <br /><br />

                {t("web.note.n2.t1")} <b>{t("web.note.n2.t2")}</b> {t("web.note.n2.t3")} <br /><br />

                <h5><b>{t("web.modifications.what.title")}</b></h5>
                <ul>
                    <li><b className="blue-text">{t("web.modifications.what.m1")}</b>: {t("web.modifications.what.mt1")}</li>
                    <li><b className="blue-text">{t("web.modifications.what.m2")}</b>: {t("web.modifications.what.mt2")}</li>
                </ul>

                <h5><b>{t("web.modifications.menu.title")}</b></h5>
                <ul>
                    <li><b className="blue-text">{t("web.modifications.menu.m1")}</b>: {t("web.modifications.menu.mt1")}</li>
                    <li><b className="blue-text">{t("web.modifications.menu.m2")}</b>: {t("web.modifications.menu.mt2")}</li>
                    <li><b className="blue-text">{t("web.modifications.menu.m3")}</b>: {t("web.modifications.menu.mt3")}</li>
                </ul>

                <h5><b>{t("web.modifications.pages.title")}</b></h5>
                <ul>
                    <li><b className="blue-text">{t("web.modifications.pages.p1")}</b>: {t("web.modifications.pages.pt1")}</li>
                    <i>{t("web.modifications.pages.text")}</i>
                </ul>

                <h5><b>{t("web.packs.title")}</b></h5>
                <div className="row">
                    <div className="col-sm">
                        <b>{t("web.packs.hosting.title")}</b>
                        <ul>
                            <li><b className="blue-text">{t("web.packs.hosting.h1")}</b>: {t("web.packs.hosting.ht1")}</li>
                            <li><b className="blue-text">{t("web.packs.hosting.h2")}</b>: {t("web.packs.hosting.ht2")}</li>
                            <li><b className="blue-text">{t("web.packs.hosting.h3")}</b>: {t("web.packs.hosting.ht3")}</li>
                            <li><b className="blue-text">{t("web.packs.hosting.h4")}</b>: {t("web.packs.hosting.ht4")}</li>
                        </ul>
                    </div>
                    <div className="col-sm">
                        <b>{t("web.packs.modifications.title")}</b>
                        <ul>
                            <li><b className="blue-text">{t("web.packs.modifications.m1")}</b>: {t("web.packs.modifications.mt1")}</li>
                            <li><b className="blue-text">{t("web.packs.modifications.m2")}</b>: {t("web.packs.modifications.mt2")}</li>
                        </ul>
                    </div>
                </div>

                <br />

                <h3><b>{t("web.request.title")}</b></h3>
                <form className="form-01" action="https://formsubmit.co/18de078091afbacf22c699ea164af003" method="POST">
                    <i>* {t("web.request.fields")}</i> <br />

                    <input type="hidden" name="_next" value="https://blauker.com/request-sent" />
                    <input type="hidden" name="_template" value="table" />
                    <input type="hidden" name="_subject" value="" />

                    <div className="center-elem">
                        <div className="mt-3">
                            {t("web.request.types.t")} * <br />
                            <select name="Servicio" id="service" required>
                                <option name="Service" value="null" selected disabled hidden>{t("web.request.types.select")}</option>
                                <option name="Service" value="none" disabled>  </option>
                                <option name="Service" value="Pregunta: Web">{t("web.request.types.t1")}</option>
                                <option name="Service" value="Solicitud de Web">{t("web.request.types.t2")}</option>
                                <option name="Service" value="none" disabled>  </option>
                                <option name="Service" value="Pregunta: Clases">{t("web.request.types.t3")}</option>
                                <option name="Service" value="Solicitud de Clases">{t("web.request.types.t4")}</option>
                                <option name="Service" value="none" disabled>  </option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm mt-3">
                            {t("web.request.r1")} * <br />
                            <input type="text" name="Nombre" id="nombre" required />
                        </div>
                        <div className="col-sm mt-3">
                            {t("web.request.r2")} * <br />
                            <input type="email" name="Email" id="email" required />
                        </div>
                        <div className="col-sm mt-3">
                            {t("web.request.r3")} * <br />
                            <input type="text" name="Empresa" id="empresa" required />
                        </div>
                        <div className="col-sm mt-3">
                            {t("web.request.r4")} * <br />
                            <input type="text" name="Localizacion" id="localizacion" required />
                        </div>
                        <div className="col-sm mt-3">
                            {t("web.request.r5")} <br />
                            <input type="text" name="Telefono" id="telefono" />
                        </div>
                    </div>

                    <div className="mt-3">
                        {t("web.request.r6")} * <br />
                        <textarea name="Mensaje" id="mensaje" cols="46" rows="5" required></textarea>
                    </div>


                    <div className="center-elem mt-3">
                        <button type="submit">{t("web.request.send")}</button>
                    </div>
                </form>

            </div>
        </div>
    );
}