import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import './7DSCC.css';

import Error404 from './pages/Error404';

import Nav from './Nav';
import LogoNav from './LogoNav';
import SDSCCNav from './SDSCCNav';
import Footer from './Footer';

import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';

import SDSCC from './pages/SDSCCCalculator'

import CreateWeb from "./pages/CreateWeb";

import RequestSent from './pages/RequestSent'

// import Naeniam from './pages/Naeniam'
// import ProtocoloNES from './pages/ProtocoloNES'

export default function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path='/' exact render={props => <div> <LogoNav/><Nav/><Home /> </div>} />
          <Route path='/about' render={props => <div> <LogoNav/><Nav/><About /> </div>} />
          <Route path='/projects' render={props => <div> <LogoNav/><Nav/><Projects /> </div>} />
          <Route path='/create-your-web' render={props => <div> <LogoNav/><Nav/><CreateWeb /> </div>} />

          <Route path='/7ds-cc-calculator' render={props => <div> <SDSCCNav/><Nav/><SDSCC /> </div>} />

          <Route path='/request-sent' render={props => <div> <LogoNav/><Nav/><RequestSent /> </div>} />

          <Route path="*" render={props => <div> <LogoNav/><Nav/><Error404 /> </div>} />
        </Switch>
        <Footer />
      </div >
    </Router>
  );
}