import React from "react";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

export default function Error404() {
    const [t] = useTranslation('global');

    return (
        <div className="Error404">
            <Helmet>
                <link rel="icon" href="iconF.ico" />
                <title>Error 404</title>
                <meta name="description" content="This page does not exist." />
            </Helmet>
            <div className="container">
                <h1><b>Error 404: {t("error404")}</b></h1>
            </div>
        </div>
    );
}