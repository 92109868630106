import React from "react";
import { useTranslation } from "react-i18next"

import { Helmet } from "react-helmet";

export default function About() {
    const [t] = useTranslation('global');

    return (
        <div className="Template-left">
            <Helmet>
                <title>Blauker - {t("nav.op3")}</title>
                <meta name="description" content="Information about Blauker, (is like a CV + extras)." />
            </Helmet>
            <div className="container">
                <div id="information">
                    <h1><b>{t("about.title.t1")} <d className="blue-text">{t("about.title.t2")}</d>:</b></h1>

                    {t("about.text.line1")} <br />
                    {t("about.text.line2")} <br />
                    {t("about.text.line3")} <br />
                    {t("about.text.line4")}<br />
                </div>

                <br /> <br />

                <div id="basic info">
                    <h3> <b>{t("about.basic.text")}</b></h3>
                    <ul>
                        <li><b className="blue-text">{t("about.basic.name1")}</b>: {t("about.basic.name2")}</li>
                        <li><b className="blue-text">{t("about.basic.bd1")}</b>: {t("about.basic.bd2")}</li>
                        <li><b className="blue-text">{t("about.basic.location1")}</b>: {t("about.basic.location2")}</li>
                    </ul>
                </div>

                <br />

                <div id="experience">
                    <h3><b>{t("about.experience.text")}</b></h3>
                    <h5><b>{t("about.experience.academic.text")}</b></h5>
                    <ul>
                        <li><b className="blue-text">{t("about.experience.academic.a1")}</b>: {t("about.experience.academic.at1")}</li>
                        <li><b className="blue-text">{t("about.experience.academic.a2")}</b>: {t("about.experience.academic.at2")}</li>
                        <li><b className="blue-text">{t("about.experience.academic.a3")}</b>: {t("about.experience.academic.at3")}</li>
                    </ul>

                    <h5><b>{t("about.experience.complementary.text")}</b></h5>
                    <ul>
                        <li><b className="blue-text">{t("about.experience.complementary.c1")}</b>: {t("about.experience.complementary.ct1")}</li>
                        <li><b className="blue-text">{t("about.experience.complementary.c2")}</b>: {t("about.experience.complementary.ct2")}</li>
                        <li><b className="blue-text">{t("about.experience.complementary.c3")}</b>: {t("about.experience.complementary.ct3")}</li>
                    </ul>

                    <h5><b>{t("about.experience.profesional.text")}</b></h5>
                    <ul>
                        <li><b className="blue-text">{t("about.experience.profesional.p1")}</b>: {t("about.experience.profesional.pt1")}</li>
                        <li><b className="blue-text">{t("about.experience.profesional.p2")} <a href="https://gameforge.com/" target="_blank" rel="noopener noreferrer">Gameforge</a>)</b>: {t("about.experience.profesional.pt2")}</li>
                        <li><b className="blue-text">{t("about.experience.profesional.p3")}</b>: {t("about.experience.profesional.pt3")} - <a className="blue-text-l" href="./projects">{t("about.experience.profesional.pl3")}</a> </li>
                        <li><b className="blue-text">{t("about.experience.profesional.p4")}</b>: {t("about.experience.profesional.pt4")} - Freelance</li>
                        <li><b className="blue-text">{t("about.experience.profesional.p5")}</b>: {t("about.experience.profesional.pt5")} - Freelance</li>
                        <li><b className="blue-text">{t("about.experience.profesional.p6")} <a href="https://nosvoid.com/" target="_blank" rel="noopener noreferrer">Nosvoid.com</a>)</b>: {t("about.experience.profesional.pt6")}</li>
                        <li><b className="blue-text">{t("about.experience.profesional.p7")} <a href="https://wiki.nosvoid.com/" target="_blank" rel="noopener noreferrer">wiki.nosvoid.com</a>)</b>: {t("about.experience.profesional.pt7")} - Freelance</li>
                        <li><b className="blue-text">{t("about.experience.profesional.p8")}</b>: {t("about.experience.profesional.pt8")}</li>
                        <li><b className="blue-text">{t("about.experience.profesional.p9")} <a href="https://nosbreak.com/" target="_blank" rel="noopener noreferrer">Nosbreak.com</a>)</b>: {t("about.experience.profesional.pt9")} - Freelance</li>
                        <a href="http://" target="_blank" rel="noopener noreferrer"></a>
                    </ul>

                    <h5><b>{t("about.experience.skills.text")}</b></h5>
                    <ul>
                        <li><b className="blue-text">{t("about.experience.skills.s1")}</b>: {t("about.experience.skills.st1")}</li>
                        <li><b className="blue-text">{t("about.experience.skills.s2")}</b>: {t("about.experience.skills.st2")}</li>
                        <li><b className="blue-text">{t("about.experience.skills.s3")}</b>: {t("about.experience.skills.st3")}</li>
                        <li><b className="blue-text">{t("about.experience.skills.s4")}</b>: {t("about.experience.skills.st4")}</li>
                        <li><b className="blue-text">{t("about.experience.skills.s5")}</b>: {t("about.experience.skills.st5")}</li>
                    </ul>

                    <h5><b>{t("about.experience.lang.text")}</b></h5>
                    <ul>
                        <li><b className="blue-text">{t("about.experience.lang.l1")}</b>: {t("about.experience.lang.lt1")}</li>
                        <li><b className="blue-text">{t("about.experience.lang.l2")}</b>: {t("about.experience.lang.lt2")}</li>
                        <li><b className="blue-text">{t("about.experience.lang.l3")}</b>: {t("about.experience.lang.lt3")}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}