import React from "react";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

export default function RequestSent() {
    const [t] = useTranslation('global');

    return (
        <div className="RequestSent">
            <Helmet>
                <title>Thanks</title>
            </Helmet>
            <div className="container">
                <h1><b>{t("request.t1")}</b></h1>
                <h4>{t("request.t2")}</h4>
                <h4>{t("request.t3")}</h4> 
            </div>
        </div>
    );
}