import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
    const [t, i18n] = useTranslation('global');

    const [lang, setLang] = useState(
        window.localStorage.getItem('lang')
    )

    console.log(lang)

    const setLocalStorage = value => {
        try {
            setLang(value)
            window.localStorage.setItem('lang', value)
        } catch (error) {
            console.error(error)
        }
    }

    const handleChange = () => {
        if (document.getElementById('langs').value === "es") {
            setLocalStorage('es')
            i18n.changeLanguage('es')
        }
        else {
            setLocalStorage('en')
            i18n.changeLanguage('en')
        }
    }

    window.onload = function () {
        if (window.localStorage.getItem('lang') === null) {
            document.getElementById('langs').value = "es"
        }
        else {
            if (window.localStorage.getItem('lang') === 'es') {
                document.getElementById('langs').value = "es"
            }
            else {
                document.getElementById('langs').value = "en"
            }
        }
    };

    return (
        <footer>
            <div class="text-center">
                <div className="row font-2">
                    <div className="col-sm mb-2">
                        <d className="text-bigger">{t("footer.contact")}</d> <br />
                        <a href="mailto:blauker.contact@gmail.com">blauker.contact@gmail.com</a>
                    </div>
                    <div className="col-sm">
                        <d className="text-bigger">{t("footer.lang")}</d> <br />
                        <select class="langSelector" id="langs" onChange={handleChange}>
                            <option value="es">🇪🇸​ {t("langs.lang1")}</option>
                            <option value="en">🇬🇧 {t("langs.lang2")}</option>
                        </select>
                    </div>
                </div>
                <hr />
                <div>
                    © 2023 Blauker -- {t("footer.line1")} <br />
                    {t("footer.line2")}
                </div>
            </div>
        </footer>
    );
}