import React from "react";
import './App.css';

export default function Nav() {
    return (
        <div className="Logo">
            <a href="/">
                <img className="logo-header-responsive" src="images/Blauker_logo_white.png" alt="blauker_logo" />
            </a>
        </div>
    );
}
