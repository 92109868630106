import React from "react";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

export default function SDSCCCalculator() {
    const [t] = useTranslation('global');

    return (
        <div className="Calculator">
            <Helmet>
                <link rel="icon" href="./iconSDS.ico" />
                <title>7DS CC Calculator</title>
            </Helmet>
            <div className="margin20"></div>
            <div className="container">
                <h1><b>{t("7dscc.title.t1")} <d className="blue-text">{t("7dscc.title.t2")}</d>:</b></h1>
                <h4><b>{t("7dscc.subtitle.b")}</b> {t("7dscc.subtitle.t1")} <a className="blue-text-l" href="https://7dsgc.netmarble.com/es#introduce">{t("7dscc.subtitle.l")}</a>{t("7dscc.subtitle.t2")}</h4>
                {t("7dscc.text.line1")}
                <ul>
                    <li>{t("7dscc.text.li1")}</li>
                    <li>{t("7dscc.text.li2")}</li>
                    <li>{t("7dscc.text.li3")}</li>
                    <li>{t("7dscc.text.li4")}</li>
                    <li>{t("7dscc.text.li5")}</li>
                    <li>{t("7dscc.text.li6")}</li>
                    <li>{t("7dscc.text.li7")}</li>
                </ul>
                {t("7dscc.text.line2")}<br />
                {t("7dscc.text.line3")}<br /><br />

                <b>{t("7dscc.card.title")} [01-04-2023]</b>
                <div className="googlePlayCard">
                    <h5><b>{t("7dscc.card.play")}</b></h5>
                    <div className="row">
                        <div class="col-sm mt-2">
                            <d className="mini-font">{t("7dscc.card.views")}</d> <br />
                            <h5><b>+484.182</b></h5>
                        </div>
                        <div class="col-sm mt-2">
                            <d className="mini-font">{t("7dscc.card.downloads")}</d> <br />
                            <h5><b>+45.980</b></h5>
                        </div>
                    </div>
                </div>

                <br /><br />
                <h2 class="text-center"><b>{t("7dscc.download.t1")} <d className="blue-text">{t("7dscc.download.t2")}</d>!</b></h2>
                <div className="row text-center">
                    <div class="col-sm mb-2">
                        <h6><i>{t("7dscc.download.t3")} <d className="blue-text">{t("7dscc.download.t4")}</d>.</i></h6>
                        <a href="https://play.google.com/store/apps/details?id=com.GiyuuDev.SevenDSCCCalculator&hl=es&gl=US">
                            <img className="banner-badge mt-m10" src="images/Badges/GooglePlayBadge.png" alt="" /></a>
                    </div>
                    <div class="col-sm">
                        <h6><i>{t("7dscc.download.t3")} <d className="blue-text">{t("7dscc.download.t5")}</d>.</i></h6>
                        <a href="7dscccalculator/download/7DS_CC_Calculator_Latest.zip" download>
                            <img className="banner-badge mt-m10" src="images/Badges/WindowsBadge.png" alt="" /></a>
                    </div>
                </div>

                <br /> <br />

                <div class="text-center sdsgame-nomargin">
                    <h2><b>{t("7dscc.play.t1")} <d className="blue-text">{t("7dscc.play.t2")}</d>!</b></h2>
                    <h6><i>{t("7dscc.play.t3")} <d className="blue-text">{t("7dscc.play.t4")}</d>.</i></h6>
                </div>
                <div className="text-center sds-container">
                    <iframe className="sds-game" title="7dsGame" src="7dscccalculator/HTML1/index.html" frameBorder="1"></iframe>
                </div>

                {/*<h3><b>{t("7dscc.ss.ss1")} <d className="blue-text">{t("7dscc.ss.ss2")}</d>:</b></h3> 
                <br />
                <div className="text-center">
                    <img className="phone-img" src="images/7DSCCCalculator/img-1.png" alt="" /><br /><br />
                    <img className="phone-img" src="images/7DSCCCalculator/img-2.png" alt="" /><br /><br />
                    <img className="pc-img" src="images/7DSCCCalculator/img-3.png" alt="" /><br />
                </div>*/}

                <div className="margin20"></div>
            </div>

            <div className="margin20"></div>
        </div>
    );
}