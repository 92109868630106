import React from "react";
import { useTranslation } from "react-i18next";


export default function Nav() {
    const [t] = useTranslation('global');



    return (
        <nav>
            <ul class="nav justify-content-center">
                <li class="nav-item nav-text">
                    <a class="nav-link" href="/">{t("nav.op1")}</a>
                </li>
                <li class="nav-item nav-text">
                    <a class="nav-link" href="/projects">{t("nav.op2")}</a>
                </li>
                <li class="nav-item nav-text">
                    <a class="nav-link" href="/about">{t("nav.op3")}</a>
                </li>
                {/*
                <li class="nav-item dropdown nav-text">
                    <a class="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {t("nav.op4")}
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="/create-your-web">{t("nav.op5")}</a>
                        <a class="dropdown-item" href="/lessons">{t("nav.op6")}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="mailto:blauker.contact@gmail.com">{t("nav.op7")}</a>
                    </div>
                </li>
                ////
                <li class="nav-item nav-text">
                    <button id="navButtonES" className="nav-button" onClick={() => {
                        setLocalStorage('es')
                        i18n.changeLanguage('es')
                        document.getElementById('navButtonEN').className = 'nav-button';
                        document.getElementById('navButtonES').className += ' nb-active';
                    }}>
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAflBMVEX/AAD9AAD3AADxAADrAAD/eXn9bGz8YWH8WVn6UVH5SEj5Pz/3NDT0Kir9/QD+/nL+/lT18lDt4Uf6+j/39zD39yf19R3n5wDxflXsZ1Pt4Y3x8zr0wbLs1NXz8xPj4wD37t3jmkvsUU/Bz6nrykm3vJ72IiL0FBTyDAvhAABEt4UZAAAAX0lEQVR4AQXBQUrFQBBAwXqTDkYE94Jb73+qfwVRcYxVQRBRToiUfoaVpGTrtdS9SO0Z9FR9lVy/g5c99+dKl30N5uxPuviexXEc9/msC7TOkd4kHu/Dlh4itCJ8AP4B0w4Qwmm7CFQAAAAASUVORK5CYII=" alt=""></img>
                        &nbsp;{t("nav.l1")}</button>
                </li>
                <li class="nav-item nav-text">
                    <button id="navButtonEN" className="nav-button" onClick={() => {
                        setLocalStorage('en')
                        i18n.changeLanguage('en')
                        document.getElementById('navButtonES').className = 'nav-button';
                        document.getElementById('navButtonEN').className += ' nb-active';
                    }}>
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAt1BMVEWSmb66z+18msdig8La3u+tYX9IaLc7W7BagbmcUW+kqMr/q6n+//+hsNv/lIr/jIGMnNLJyOP9/fyQttT/wb3/////aWn+YWF5kNT0oqz0i4ueqtIZNJjhvt/8gn//WVr/6+rN1+o9RKZwgcMPJpX/VFT9UEn+RUX8Ozv2Ly+FGzdYZrfU1e/8LS/lQkG/mbVUX60AE231hHtcdMb0mp3qYFTFwNu3w9prcqSURGNDaaIUMX5FNW5wYt7AAAAAjklEQVR4AR3HNUJEMQCGwf+L8RR36ajR+1+CEuvRdd8kK9MNAiRQNgJmVDAt1yM6kSzYVJUsPNssAk5N7ZFKjVNFAY4co6TAOI+kyQm+LFUEBEKKzuWUNB7rSH/rSnvOulOGk+QlXTBqMIrfYX4tSe2nP3iRa/KNK7uTmWJ5a9+erZ3d+18od4ytiZdvZyuKWy8o3UpTVAAAAABJRU5ErkJggg==" alt=""></img>
                        &nbsp;{t("nav.l2")}</button>
                </li>*/}
            </ul>
        </nav>
    );
}


