import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import global_es from './lang/es/global.json';
import global_en from './lang/en/global.json';

if (localStorage.getItem('lang') === null){
    i18next.init({
        interpolation: { escapeValue: false },
        lng: 'es',
        resources: {
            es: {
                global: global_es,
            },
            en: {
                global: global_en,
            }
        }
    });
}
else{
    i18next.init({
        interpolation: { escapeValue: false },
        lng: window.localStorage.getItem('lang'),
        resources: {
            es: {
                global: global_es,
            },
            en: {
                global: global_en,
            }
        }
    });
}

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>,
    document.getElementById('root'));
